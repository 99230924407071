import React from "react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

// import FormLabel from "@ats/src/components/forms/FormLabel";
// import FormInput from "@ats/src/components/forms/FormInput";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormContainer from "@ats/src/components/forms/FormContainer";
// import FormToggleSwitch from "@ats/src/components/forms/FormToggleSwitch";

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { useToastContext } from "@shared/context/ToastContext";

import {
  organizationPlanOptions,
  adminOrganizationFlipperGroupOptions,
  adminBooleanOptions,
  adminFraudRatingOptions,
} from "@ats/src/lib/lookups";

import {
  useUpdateOrganization,
  useOrganization,
  useTransferOrganizationOwnership,
} from "@shared/queryHooks/admin/useOrganization";

type Props = { organization: any; onCancel: () => void };

function AdminOrganizationModal(props: Props) {
  const [organization, setOrganization] = React.useState(props.organization);
  const [owner, setOwner] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const addToast = useToastContext();

  const {
    mutate: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdateOrganization();

  const {
    mutate: transferOwnership,
    isLoading: isLoadingTransferOwnership,
  } = useTransferOrganizationOwnership();

  const { data: organizationData, isLoading: isLoadingOrganization } = useOrganization(
    props.organization,
  );

  const {
    id,
    name,
    plan,
    flipperGroup,
    canSendBulkMessages,
    canEnableLinkedin,
    fraudRating,
  } = organization;

  const currentOwner = organizationData?.users.find((user) => {
    return user.id == organizationData.ownerId;
  });

  React.useEffect(() => {
    setOwner(
      currentOwner
        ? currentOwner?.currentOrganizationUserId //{ value: currentOwner?.currentOrganizationUserId, label: currentOwner?.fullName }
        : null,
    );
  }, [currentOwner]);

  const organizationMemberOptions = organizationData
    ? organizationData.users.map((user, index) => {
        return { value: user.currentOrganizationUserId, label: user.fullName };
      })
    : [];

  window.logger("%c[AdminOrganizationEditModal] RENDER", "background-color: #8fb4d8", {
    organization,
    organizationData,
    currentOwner,
  });

  const onCloseModal = () => {
    props.onCancel();
  };

  const handleOnInputChange = (name, value) => {
    window.logger("%c[AdminOrganizationEditModal] handleOnInputChange", "color: #1976D2", {
      name,
      value,
    });
    setOrganization({ ...organization, [name]: value });
  };

  const handleChangeBulkMessagingEnabled = (name, value) => {
    window.logger("%c[AdminOrganizationEditModal] handleOnInputChange", "color: #1976D2", {
      name,
      value,
    });

    setOrganization({ ...organization, [name]: value });

    // updateOrganization(
    //   { id, canSendBulkMessages: value },
    //   {
    //     onSuccess: () => {
    //       addToast({
    //         kind: "success",
    //         title: "Bulk messaging enabled updated",
    //       });
    //     },
    //   },
    // );
  };

  const handleChangeOwner = (name, value) => {
    window.logger("%c[AdminOrganizationEditModal] handleChangeOwner", "color: #1976D2", {
      name,
      value,
    });

    setOwner(value);

    transferOwnership(
      { id: organization.id, organizationUserId: value },
      {
        onSuccess: (data) => {
          window.logger("%c[AdminOrganizationEditModal] transferOwnership", "color: #1976D2", {
            data,
          });
        },
      },
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setErrors(null);
    updateOrganization(
      { id, name, plan, flipperGroup, canSendBulkMessages, canEnableLinkedin, fraudRating },
      {
        onSuccess: (data) => {
          window.logger("%c[AdminOrganizationEditModal] handleUpdate", "color: #1976D2", { data });
          props.onCancel();
        },
      },
    );
  };

  const submitButton = (
    <Styled.ButtonContainer>
      <Button type="button" onClick={handleSubmit} loading={isLoadingTransferOwnership}>
        Update organization
      </Button>
      <Button styleType="secondary" onClick={onCloseModal}>
        Cancel
      </Button>
    </Styled.ButtonContainer>
  );

  return (
    <CenterModal headerTitleText={`Edit: ${organization.name}`} onCancel={onCloseModal}>
      <FormContainer
        // onSubmit={this.onSubmit}
        errors={errors}
        buttons={submitButton}
        className="admin-modal"
      >
        <FormSelect
          onChange={handleOnInputChange}
          name="flipperGroup"
          label="Feature Flipper group"
          description="This will opt the Organization into certain Features"
          className=""
          value={flipperGroup}
          options={adminOrganizationFlipperGroupOptions}
          errors={errors}
        />
        <FormSelect
          onChange={handleOnInputChange}
          name="plan"
          label="Plan"
          description="This is dangerous to change all willy nilly!"
          className=""
          value={plan}
          options={organizationPlanOptions}
          errors={errors}
        />
        <FormSelect
          onChange={handleChangeOwner}
          name="owner"
          label="Owner"
          description="Change the org owner"
          className=""
          value={owner}
          options={organizationMemberOptions}
          errors={errors}
        />
        <FormSelect
          onChange={handleChangeBulkMessagingEnabled}
          name="canSendBulkMessages"
          label="Can send bulk messages"
          description="Change if they can send bulk messages"
          className=""
          value={canSendBulkMessages}
          options={adminBooleanOptions}
          errors={errors}
        />
        <FormSelect
          onChange={handleOnInputChange}
          name="canEnableLinkedin"
          label="Can enable LinkedIn"
          description="Change if they can enable the LinkedIn Integration"
          className=""
          value={canEnableLinkedin}
          options={adminBooleanOptions}
          errors={errors}
        />
        <FormSelect
          onChange={handleOnInputChange}
          name="fraudRating"
          label="Fraud Rating"
          description="Set the fraud rating for this organization"
          className=""
          value={fraudRating || "none"}
          options={adminFraudRatingOptions}
          errors={errors}
        />
      </FormContainer>
    </CenterModal>
  );
}

export default AdminOrganizationModal;

/* Styled Components
======================================================= */
const Styled: any = {};

Styled.ClaimedBadge = styled.h3((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationModal_ClaimedBadge;
    ${[t.mb(5), t.text.h5]}
    color: ${t.color.red[600]};
  `;
});

Styled.ScrapedURL = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationModal_ScrapedURL;
    ${[t.mb(5)]}

    a:hover {
      text-decoration: underline;
    }
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AdminOrganizationModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});

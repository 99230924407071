import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";
import Button from "@ats/src/components/shared/Button";
import HeaderBar from "@ats/src/views/sessions/components/HeaderBar";
import Styled from "@ats/src/views/sessions/components/Styled";

import { useSendPasswordResetEmail } from "@shared/queryHooks/useSession";
import { validateEmail } from "@shared/lib/validateWithYup";
import { useToastContext } from "@shared/context/ToastContext";

function PasswordResetRequest(props) {
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const addToast = useToastContext();

  const { mutate: sendPasswordResetEmail, isLoading } = useSendPasswordResetEmail();

  const handleFormInputChange = (name, value) => {
    setEmail(value);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    window.logger("%c[PasswordResetRequest] handleSubmitForm", "color: #1976D2", { email });

    const [isValid, validationErrors] = await validateEmail({ email });

    if (isValid) {
      sendPasswordResetEmail(
        { email },
        {
          onSuccess: () => {
            setEmail("");
            setErrors(null);
            addToast({ title: `Password reset email sent`, kind: "success" });
          },
          onError: (response: any) => {
            setErrors(response.data.errors);
          },
        },
      );
    } else {
      setErrors(validationErrors);
    }
  };

  const submitButton = (
    <Button type="submit" className="button-primary" disabled={isLoading} loading={isLoading}>
      Submit request
    </Button>
  );

  return (
    <Styled.Container>
      <HeaderBar />
      <Styled.UI>
        <Styled.Form>
          <Helmet title="Reset password" />
          <h2>Request password reset</h2>
          <FormContainer onSubmit={handleSubmitForm} errors={errors} buttons={submitButton}>
            <FormInput
              onChange={handleFormInputChange}
              name="email"
              placeholder=""
              label="Email address"
              inputType="email"
              value={email}
              errors={errors}
            />
          </FormContainer>
        </Styled.Form>
        <Styled.Links>
          <Link to="/login">Log in by password</Link>
          <Link to="/auth">Log in by email link</Link>
        </Styled.Links>
      </Styled.UI>
    </Styled.Container>
  );
}

export default withRouter(PasswordResetRequest);

/* eslint-disable @getify/proper-arrows/return */

import React, { useCallback, useEffect, useState } from "react";
import { useTransition, animated } from "react-spring";
import { Link } from "react-router-dom";
import { guid } from "@ats/src/lib/utils/helpers";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";

import Icon from "@ats/src/components/shared/Icon";

type ToastKind = "success" | "danger" | "error" | "warning" | "info";

interface Toast {
  kind: ToastKind;
  title: string;
  delay?: number;
  linkTo?: string;
  externalLink?: {
    href: string;
    text: string;
  };
}

type AddToast = (toast: Toast) => void;

const ToastContext = React.createContext<AddToast | undefined>(undefined);

function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const ref = React.useRef(null);
  const theme: any = useTheme();

  const MAX_TOAST_COUNT = 2;

  useEffect(() => {
    // Prevent toast stacking in the UI by limiting to the 2 newest toasts
    const limitToastCount = (toasts) => {
      if (toasts.length > MAX_TOAST_COUNT) {
        setToasts((prevToasts) => prevToasts.slice(-MAX_TOAST_COUNT));
      }
    };

    if (toasts.length > 0) {
      limitToastCount(toasts)

      const currentToast = toasts[0];
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        currentToast.delay || 2000,
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast) {
      setToasts((toasts) => [...toasts, { ...toast, id: guid() }]);
    },
    [setToasts],
  );

  const removeToast = useCallback(
    function (toast) {
      // window.logger("%c[ToastContext] removeToast", "color: #1976D2", { toast });
      setToasts(toasts.filter((t) => t.id !== toast.id));
    },
    [setToasts, toasts],
  );

  const transitions = useTransition<any, any>(toasts, {
    from: {
      opacity: 0,
      height: 0,
    },
    enter: (item) => async (next) => {
      // window.logger("%c[ToastContect] enter", "color: #FF7602", {
      //   item,
      //   next,
      //   offsetHeight: refMap.get(item).offsetHeight,
      // });
      return await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight,
      });
    },
    // leave fires when the element is leaving
    leave: {
      opacity: 0,
    },
    config: {
      tension: 320,
      friction: 36,
    },
    onRest: (item) => {
      // return item;
      // setToasts((state) => state.filter((i) => i.id !== item.id))
    },
  });

  // React.useEffect(() => {
  //   children((msg) => setToasts((state) => [...state, { key: guid(), msg }]));
  // }, []);

  const iconNode = (toast) => {
    let icon;

    switch (toast.kind) {
      case "success":
        icon = <Icon name="check-circle" />;
        break;
      case "danger":
      case "error":
        icon = <Icon name="alert-triangle" />;
        break;
      case "warning":
        icon = <Icon name="alert-circle" />;
        break;
      case "info":
        icon = <Icon name="info" />;
        break;
      default:
        icon = null;
    }

    return icon;
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Styled.GrowlList>
        {transitions((props, item) => {
          // window.logger("%c[ToastContext] Toast", "color: #1976D2", { item, props, key, index });
          return (
            <Styled.Container style={props}>
              <Styled.Message
                // style={props}
                // key={item.id}
                onClick={() => removeToast(item)}
                ref={(ref) => ref && refMap.set(item, ref)} // allows us to get the item to get the ref
              >
                {iconNode(item)}
                <p>{item.title || item || ""}</p>
                {/* <b>{item.id}</b> */}
                {item.linkTo && <Link to={item.linkTo}>View</Link>}
                {item.externalLink && (
                  <a href={item.externalLink.href} rel="noreferrer noopener" target="_blank">
                    {item.externalLink.text || "View"}
                  </a>
                )}
              </Styled.Message>
            </Styled.Container>
          );
        })}
      </Styled.GrowlList>
    </ToastContext.Provider>
  );
}

function useToastContext() {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return context;
}

export { useToastContext, ToastProvider };

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.GrowlList = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: ModalsAndGrowls_GrowlList;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    /* z-index: 100; */
    z-index: 100000000000;

    ${t.mq["lg"]} {
      width: 30%;
    }
  `;
});

Styled.Container = styled(animated.div)((props: any) => {
  const t: any = props.theme;
  return css`
    label: GrowlNotificationContainer;
    &:last-of-type {
      ${t.mb(8)}
    }
  `;
});

Styled.Message = styled.div((props: any) => {
  // Styled.Container = styled(animated.div)((props: any) => {
  const t: any = props.theme;
  const modeStyles = t.dark
    ? css`
        background-color: ${t.color.gray[700]};
        border: 1px solid ${t.color.gray[600]};
      `
    : css`
        background-color: ${t.color.white};
        border: 1px solid ${t.color.gray[400]};
      `;

  return css`
    label: GrowlNotificationMessage;
    ${[t.p(5), t.mx(8), t.my(2), t.text.normal]}
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    background-color: transparent;
    display: flex;
    align-items: center;
    position: relative;
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    p {
      flex: 1;
    }
    a {
      ${[t.ml(2), t.text.medium]}
      color: ${t.dark ? t.color.white : t.color.black};
      flex-shrink: 0;
      &:hover {
        text-decoration: underline;
      }
    }
    &::before {
      ${t.rounded.md}
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      ${modeStyles}
    }
  `;
});
